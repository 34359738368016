import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { GuestPanelAuthService } from './guest-panel-auth.service';
import { GuestPanelService } from './guest-panel.service';

@Injectable({
  providedIn: 'root'
})
export class GuestPanelAuthGuardService implements CanActivate {

  constructor(
    public auth: GuestPanelAuthService, 
    public guestPanelService: GuestPanelService,
    public router: Router,
    public toastr: ToastrService,
  ) { }

  
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if(!this.auth.isAuthenticated()) {
      this.router.navigate(['/login'])
      return false;
    }
    else{
      let response = this.guestPanelService.getHotelGuestUserSessionData()
      if(response['status'] == 1){
        let hotelGuestUserData = response['hotelGuestUserData']
        return new Promise(res => {
          this.guestPanelService.validateCurrentGuestStayDetail(
            {'stay_id': hotelGuestUserData['guest_detail']['stay_id']})
            .subscribe(
              (result) => {
                  if(result['status'] == 1) {
                      res(true);
                  } else {
                      this.toastr.error(result['msg']);
                      this.guestPanelService.clearHotelGuestUserSessionData();
                      this.router.navigate(['/login'])
                      res(false);
                  }
              },
          );
        });
      }
    }
  }
}
